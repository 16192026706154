<template>
    <div class="the-navbar__user-meta flex items-center">
        <div class="text-right leading-tight hidden sm:block">
            <p class="font-semibold">{{ displayName }}</p>
            <small>{{ displayStatus }}</small>
        </div>

        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
            <div class="con-img ml-3">
                <vs-avatar
                    v-if="user"
                    badge
                    :text="displayName"
                    color="success"
                    :src="image"
                />
                <vs-avatar v-else badge color="danger" />
            </div>

            <vs-dropdown-menu class="vx-navbar-dropdown">
                <ul style="min-width: 9rem">
                    <li
                        @click="$router.push('/profile')"
                        class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                    >
                        <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
                        <span class="ml-2">Profile</span>
                    </li>
                    <!--
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon
              icon="MailIcon"
              svgClasses="w-4 h-4"
            />
            <span class="ml-2">Inbox</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon
              icon="CheckSquareIcon"
              svgClasses="w-4 h-4"
            />
            <span class="ml-2">Tasks</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon
              icon="MessageSquareIcon"
              svgClasses="w-4 h-4"
            />
            <span class="ml-2">Chat</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon
              icon="HeartIcon"
              svgClasses="w-4 h-4"
            />
            <span class="ml-2">Wish List</span>
          </li>
          -->

                    <vs-divider class="m-1" />

                    <li
                        class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                        @click="logout"
                    >
                        <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
                        <span class="ml-2">Logout</span>
                    </li>
                </ul>
            </vs-dropdown-menu>
        </vs-dropdown>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    computed: {
        user() {
            return this.$store.getters.user
        },
        image() {
            if (this.user.profile_image) return this.user.profile_image
            return null
        },
        displayName() {
            if (this.user) {
                if (this.user.first_name && this.user.last_name)
                    return `${this.user.first_name} ${this.user.last_name}`
                return this.user.email
            }
            return 'Annonomous User'
        },
        displayStatus() {
            if (this.user) return 'Available'
            return 'Offline'
        },
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
        },
    },
}
</script>
