export default [
    {
        url: '/',
        name: 'Home',
        slug: 'home',
        icon: 'HomeIcon',
    },
    {
        url: '',
        name: '',
        slug: '',
        icon: '',
        i18n: '',
    },
    {
        url: null,
        name: 'Wills',
        tagColor: 'warning',
        icon: 'FileIcon',
        i18n: 'Wills',
        submenu: [
            {
                url: '/wills/pipeline',
                name: 'Pipeline',
                slug: 'Pipeline',
                i18n: 'Pipeline',
            },
            {
                url: '/wills/pipeline/on_hold',
                name: 'On Hold',
                slug: 'On Hold',
                i18n: 'On Hold',
            },
            {
                url: '/wills/pipeline/to_check',
                name: 'To Check',
                slug: 'To Check',
                i18n: 'To Check',
            },
            {
                url: '/wills/pipeline/to_contact',
                name: 'To Contact',
                slug: 'To Contact',
                i18n: 'To Contact',
            },
            {
                url: '/wills/pipeline/to_approve',
                name: 'To Approve',
                slug: 'To Approve',
                i18n: 'To Approve',
            },
            {
                url: '/wills/pipeline/to_print',
                name: 'To Print',
                slug: 'To Print',
                i18n: 'To Print',
            },
            {
                url: '/wills/all/1',
                name: 'All',
                slug: 'All',
                i18n: 'All',
            },
        ],
    },
    {
        url: null,
        name: 'Users',
        tagColor: 'warning',
        icon: 'UserIcon',
        i18n: 'Users',
        submenu: [
            {
                url: '/users/all/1',
                name: 'All',
                slug: 'All',
                i18n: 'All',
            },
            {
                url: '/users/brokers/1',
                name: 'Brokers',
                slug: 'Brokers',
                i18n: 'Brokers',
            },
            {
                url: '/users/staff',
                name: 'Staff',
                slug: 'Staff',
                i18n: 'Staff',
                restricted: 'is_staff_admin',
            },
        ],
    },

    {
        url: '/advice/1',
        name: 'Advice',
        icon: 'MessageSquareIcon',
        slug: 'Advice',
    },
    {
        url: '',
        name: '',
        slug: '',
        icon: '',
        i18n: '',
    },
    /*
    {
        url: '/email',
        name: 'Email',
        icon: 'MailIcon',
        slug: 'Email',
    },

     */
    {
        url: null,
        name: 'Brokers',
        icon: 'CoffeeIcon',
        slug: "'Brokers'",
        submenu: [
            {
                url: '/brokers/1',
                name: 'Accounts',
                slug: 'Brokers',
                i18n: 'Accounts',
                restricted: 'is_staff_admin',
            },
            {
                url: null,
                name: 'Clients',
                slug: 'Clients',
                i18n: 'Clients',
                submenu: [
                    {
                        url: '/clients/all/1',
                        name: 'Clients',
                        slug: 'Clients',
                        i18n: 'Clients',
                    },
                    {
                        url: '/clients/batch',
                        name: 'Batch',
                        slug: 'Batch',
                        i18n: 'Batch',
                    },
                ],
            },
        ],
    },
    {
        url: null,
        name: 'Finance',
        icon: 'DollarSignIcon',
        slug: "'Finance'",
        restricted: 'is_staff_admin',
        submenu: [
            {
                url: '/finance/invoices',
                name: 'Invoices',
                icon: 'DollarSignIcon',
                slug: "'Invoices'",
                restricted: 'is_staff_admin',
            },
            {
                url: '/finance/commissions',
                name: 'Commissions',
                icon: 'DollarSignIcon',
                slug: "'Commissions'",
                restricted: 'is_staff_admin',
            },
            {
                url: '/finance/payments',
                name: 'Payments',
                icon: 'DollarSignIcon',
                slug: "'Payments'",
                restricted: 'is_staff_admin',
            },
        ],
    },

    {
        url: null,
        name: 'Reports',
        tagColor: 'warning',
        icon: 'BarChart2Icon',
        i18n: 'Reports',
        submenu: [
            {
                url: '/reports/basic',
                name: 'Basic',
                slug: 'Basic',
                i18n: 'Basic',
            },
            {
                url: '/reports/overview',
                name: 'Overview',
                slug: 'Overview',
                i18n: 'Overview',
                restricted: 'is_staff_admin',
            },
            {
                url: '/reports/share',
                name: 'Share',
                slug: 'Share',
                i18n: 'Share',
                restricted: 'is_staff_admin',
            },
            {
                url: '/reports/payments',
                name: 'Payments',
                slug: 'Payments',
                i18n: 'Payments',
                restricted: 'is_staff_admin',
            },
            {
                url: null,
                name: 'Brokers',
                slug: 'Brokers',
                i18n: 'Brokers',
                restricted: 'is_staff_admin',
                submenu: [
                    {
                        url: '/reports/brokers/accounts',
                        name: 'Wills & Send Logs',
                        slug: 'Brokers',
                        i18n: 'Brokers',
                        restricted: 'is_staff_admin',
                    },
                    {
                        url: '/reports/brokers/plans',
                        name: 'Plans',
                        slug: 'Broker Plans',
                        i18n: 'Broker Plans',
                        restricted: 'is_staff_admin',
                    },
                    {
                        url: '/reports/brokers/servers',
                        name: 'Servers',
                        slug: 'Broker Servers',
                        i18n: 'Broker Servers',
                        restricted: 'is_staff_admin',
                    },
                ],
            },
        ],
    },
    {
        url: '',
        name: '',
        slug: '',
        icon: '',
        i18n: '',
    },
    {
        url: null,
        name: 'System',
        tagColor: 'warning',
        icon: 'BoxIcon',
        i18n: 'System',
        restricted: 'is_staff_admin',
        submenu: [
            {
                url: '/system/coupons/all',
                name: 'Coupons Electronic',
                slug: 'Coupons Electronic',
                i18n: 'Coupons Electronic',
                restricted: 'is_staff_admin',
            },
            {
                url: '/system/coupons_batch/all',
                name: 'Coupons Printed',
                slug: 'Coupons Printed',
                i18n: 'Coupons Printed',
                restricted: 'is_staff_admin',
            },
            {
                url: '/system/scheduled_tasks',
                name: 'Scheduled Tasks',
                slug: 'Schedule Tasks',
                i18n: 'Schedule Tasks',
                restricted: 'is_staff_admin',
            },
            {
                url: null,
                name: 'Communication',
                slug: 'Communication',
                i18n: 'Communication',
                submenu: [
                    {
                        url: '/system/communication/user',
                        name: 'User',
                        slug: 'CommunicationUser',
                        i18n: 'CommunicationUser',
                        restricted: 'is_staff_admin',
                    },
                    {
                        url: '/system/communication/broker',
                        name: 'Broker',
                        slug: 'CommunicationBroker',
                        i18n: 'CommunicationBroker',
                        restricted: 'is_staff_admin',
                    },
                    {
                        url: '/system/communication/emails',
                        name: 'Emails All',
                        slug: 'CommunicationEmail',
                        i18n: 'CommunicationEmail',
                        restricted: 'is_staff_admin',
                    },
                    {
                        url: '/system/communication/sms',
                        name: 'Sms All',
                        slug: 'CommunicationSms',
                        i18n: 'CommunicationSms',
                        restricted: 'is_staff_admin',
                    },
                    {
                        url: '/system/communication/broker_logs',
                        name: 'Broker Logs',
                        slug: 'CommunicationBrokerLogs',
                        i18n: 'CommunicationBrokerLogs',
                        restricted: 'is_staff_admin',
                    },
                    {
                        url: '/system/communication/system_logs',
                        name: 'System Logs',
                        slug: 'CommunicationSystemLogs',
                        i18n: 'CommunicationSystemLogs',
                        restricted: 'is_staff_admin',
                    },
                ],
            },
        ],
    },
]
