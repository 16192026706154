var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vs-sidebar-group",class:[
        { 'vs-sidebar-group-open': _vm.openItems },
        { 'vs-sidebar-group-active': _vm.open },
        { 'disabled-item pointer-events-none': _vm.group.isDisabled },
    ],on:{"mouseover":_vm.mouseover,"mouseout":_vm.mouseout}},[_c('div',{staticClass:"group-header w-full",on:{"click":_vm.clickGroup}},[_c('span',{staticClass:"flex items-center w-full"},[(
                    _vm.group.icon ||
                    this.groupIndex > Math.floor(this.groupIndex)
                )?_c('feather-icon',{attrs:{"icon":_vm.group.icon || 'CircleIcon',"svgClasses":{ 'w-3 h-3': this.groupIndex % 1 != 0 }}}):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.verticalNavMenuItemsMin),expression:"!verticalNavMenuItemsMin"}],staticClass:"truncate mr-3 select-none"},[_vm._v(_vm._s(_vm.group.name))]),(_vm.group.tag && !_vm.verticalNavMenuItemsMin)?_c('vs-chip',{staticClass:"ml-auto mr-4",attrs:{"color":_vm.group.tagColor}},[_vm._v(_vm._s(_vm.group.tag))]):_vm._e()],1),_c('feather-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.verticalNavMenuItemsMin),expression:"!verticalNavMenuItemsMin"}],class:[{ rotate90: _vm.openItems }, 'feather-grp-header-arrow'],attrs:{"icon":_vm.$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon',"svg-classes":"w-4 h-4"}}),_c('span',{staticClass:"vs-sidebar--tooltip"},[_vm._v(_vm._s(_vm.group.name))])],1),_c('ul',{ref:"items",staticClass:"vs-sidebar-group-items",style:(_vm.styleItems)},_vm._l((_vm.group.submenu),function(groupItem,index){return _c('li',{key:index},[(groupItem.submenu && _vm.showRestricted(groupItem))?_c('v-nav-menu-group',{attrs:{"group":groupItem,"groupIndex":Number(`${_vm.groupIndex}.${index + 1}`),"open":_vm.isGroupActive(groupItem),"openHover":_vm.openHover}}):(!groupItem.submenu && _vm.showRestricted(groupItem))?_c('v-nav-menu-item',{attrs:{"icon-small":"","index":_vm.groupIndex + '.' + index,"to":groupItem.slug !== 'external' ? groupItem.url : null,"href":groupItem.slug === 'external' ? groupItem.url : null,"icon":_vm.itemIcon(_vm.groupIndex + '.' + index),"slug":groupItem.slug,"target":groupItem.target}},[_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(groupItem.name))]),(groupItem.tag)?_c('vs-chip',{staticClass:"ml-auto",attrs:{"color":groupItem.tagColor}},[_vm._v(_vm._s(groupItem.tag))]):_vm._e()],1):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }